<template>
  <NuxtPage />
</template>

<script setup>
import { ID_INJECTION_KEY } from 'element-plus'
provide(ID_INJECTION_KEY, {
  prefix: 100,
  current: 0
})
</script>

<style lang="scss">
.el-backtop{
  width: 60px;
  height: 60px;
}
.el-popover.el-popper{
  min-width: 110px;
}
.el-dialog {
  display: flex;
  flex-direction: column;
  margin:0 !important;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  /*height:600px;*/
  max-height:calc(100% - 200px);
  max-width:calc(100% - 30px);
  border-radius: 5px;
}
.el-popup-parent--hidden{
  padding-right: 0 !important;
}
.el-dialog .el-dialog__body {
  flex:1;
  overflow: auto;
}
.prefix_select_input{
  padding: 0px !important;;
  width: 100%;
  .el-input {
    .el-input__prefix{
      left: 1px;
    }
  }
  .el-input--default.el-input--prefix{
    &>.el-input__inner{
      padding-left: 100px;
    }
    .el-select{
      .el-input__inner{
        position: relative;
        top: 1px;
        padding-left: 10px;
        padding-right: 0px;
        height: 30px;
        border: none;
        box-shadow: none !important;
      }
      .el-input__suffix{
        top: 1px;
        right: 0px;
      }
    }
    .select_border{
      position: relative;
      &::after{
        content: '';
        position: absolute;
        top: 50%;
        left: -4px;
        transform: translateY(-50%);
        width: 1px;
        height: 14px;
        background: #D9D9D9;

      }
    }
  }
  .el-input--large.el-input--prefix{
    &>.el-input__inner{
      padding-left: 90px;
    }
    .el-select{
      .el-input__inner{
        position: relative;
        top: 2px;
        padding-left: 10px;
        padding-right: 0px;
        height: 36px;
        border: none;
        box-shadow: none !important;
      }
      .el-input__suffix{
        top: 1px;
        right: 0px;
      }

    }
    .select_border{
      position: relative;
      &::after{
        content: '';
        position: absolute;
        top: 50%;
        left: -4px;
        transform: translateY(-50%);
        width: 1px;
        height: 14px;
        background: #D9D9D9;

      }
    }
    .el-popper{
      padding: 0;
    }
  }
  .pop_holder {
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #606266;

  }
  .pop_border{
    position: relative;
  }
  .pop_border:before{
    content: '';
    position: absolute;
    background-color: #EBEEF5;
    width: 1px;
    right: 10px;
    height: 14px;
  }
  .te_code_enum {
    padding: 5px 0;
    width: 100%;
    max-height: 247px;

    .te_code_enum_item {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      padding: 10px 20px;

      &:hover {
        background-color: #f7f8fb;
      }
    }
  }

  .popover_mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
  }
}
.border_cell_name{
  background-color: rgba(242, 243, 245, 0.5) !important;
  box-shadow: inset 0px -1px 0px #EBEEF5;
  color: #333333;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 54px;
}
.cellName{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  height: 54px;
}
.el-message{
  z-index: 10000 !important;
}
.el-image-viewer__wrapper {
  z-index: 10001 !important;
}




html,
body,
#app {
  margin: 0;
  padding: 0;
}
#app {
  height: 100vh;
  overflow: auto;
}
html.dark {
  background: #222;
  color: white;
}

@font-face {
  font-family: 'CXGDT'; // 定义字体名称
  src: url('../assets/font/simsun.ttf'); // 文件路径
}

.pv-video-player .pv-cover .pv-icon-btn-play {
  display: none !important;
}

body{
  font-family: PingFang SC,sans-serif;
  font-size: 14px;
  color: var(--el-color-black);
}

*, :before, :after {
  box-sizing:border-box;
  border-width:0;
  border-style:solid;
  border-color:#e5e7eb
}
* {
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000
}
:root {
  -moz-tab-size:4;
  -o-tab-size:4;
  tab-size:4
}
:-moz-focusring {
  outline:1px dotted ButtonText
}
:-moz-ui-invalid {
  box-shadow:none
}
::moz-focus-inner {
  border-style:none;
  padding:0
}
::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height:auto
}
::-webkit-search-decoration {
  -webkit-appearance:none
}
::-webkit-file-upload-button {
  -webkit-appearance:button;
  font:inherit
}
[type=search] {
  -webkit-appearance:textfield;
  outline-offset:-2px
}
abbr[title] {
  -webkit-text-decoration:underline dotted;
  text-decoration:underline dotted
}
a {
  color:inherit;
  text-decoration:inherit
}
body {
  margin:0;
  font-family:inherit;
  line-height:inherit
}
b {
  font-weight:bolder
}
button {
  text-transform:none;
  background-color:transparent;
  background-image:none
}
button, [type=button], [type=reset], [type=submit] {
  -webkit-appearance:button
}
button, [role=button] {
  cursor:pointer
}
html {
  -webkit-text-size-adjust:100%;
  font-family:ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
  line-height:1.5;
}
h5 {
  font-size:inherit;
  font-weight:inherit
}
input, button {
  font-family:inherit;
  font-size:100%;
  line-height:1.15;
  margin:0;
  padding:0;
  line-height:inherit;
  color:inherit
}
img {
  border-style:solid;
  vertical-align:middle
}
input::placeholder {
  opacity:1;
  color:#9ca3af
}
input::webkit-input-placeholder {
  opacity:1;
  color:#9ca3af
}
input::-moz-placeholder {
  opacity:1;
  color:#9ca3af
}
input:-ms-input-placeholder {
  opacity:1;
  color:#9ca3af
}
input::-ms-input-placeholder {
  opacity:1;
  color:#9ca3af
}
canvas, iframe, video {
  display:block;
  vertical-align:middle
}
img, video {
  max-width:100%;
  height:auto
}
p, h5 {
  margin:0
}
// table {border-top: 1px solid #ccc;border-left: 1px solid #ccc;
// }
// table td,
// table th {border-bottom: 1px solid #ccc;border-right: 1px solid #ccc;padding: 3px 5px;
// }
// table th {border-bottom: 2px solid #ccc;text-align: center;
// }/* blockquote 样式 */
// blockquote {display: block;border-left: 8px solid #d0e5f2;padding: 5px 10px;margin: 10px 0;line-height: 1.4;font-size: 100%;background-color: #f1f1f1;
// }/* code 样式 */
// code {display: inline-block;*display: inline;*zoom: 1;background-color: #f1f1f1;border-radius: 3px;padding: 3px 5px;margin: 0 3px;
// }
// pre code {display: block;
// }/* ul ol 样式 */
// ul, ol {margin: 10px 0 10px 20px;
// }
ul, ol {
  list-style:none;
  margin:0;
  padding:0
}
.border, .\.border {
  border-width:1px
}
.block {
  display:block
}
.tab, .tab\.paneName {
  -moz-tab-size:4;
  -o-tab-size:4;
  tab-size:4
}
.relative {
  position:relative
}
[right~="10"] {
  right:2.5rem
}
[top~="16vh"] {
  top:16vh
}
[resize~=none] {
  resize:none
}
[content~=price_box_right] {
  content:"price_box_right"
}
.visible {
  visibility:visible
}
.duration {
  transition-duration:.15s
}
.blur {
  --tw-blur: blur(8px)
}


.no_select {
  user-select: none;
}
.c_333 {
  color: #333;
}
.c_999 {
  color: #999;
}
.font12{
  font-size: 12px;
}
.font14{
  font-size: 14px;
}
.font16{
  font-size: 16px;
}
.font18{
  font-size: 18px;
}
.font20{
  font-size: 20px;
}
.font24{
  font-size: 24px;
}
.font32{
  font-size: 32px;
}
.blue{
  color: var(--el-color-primary);
}
.blue50{
  color: var(--el-color-primary-light-5);
}
.wh100pc{
  width: 100%;
  height: 100%;
}
.mgt10{
  margin-top: 10px;
}
.mgt20{
  margin-top: 20px;
}
.mgr6 {
  margin-right: 6px !important;
}
.mgr10 {
  margin-right: 10px !important;
}
.mgr20 {
  margin-right: 20px !important;
}
.mgr30{
  margin-right: 30px !important;
}
.mgl10 {
  margin-left: 10px !important;
}
.mgl20 {
  margin-left: 20px !important;
}
.mgb10{
  margin-bottom: 10px !important;
}
.mgb20{
  margin-bottom: 20px !important;
}
.flex {display: -webkit-flex;display: flex;}
.flex-item {-webkit-flex: 1;flex: 1;}
.flex-item-center{-webkit-flex: 1;flex:1;}
.flex-item-2 {-webkit-flex: 2;flex: 2;}
.flex-item-3 { -webkit-flex: 3;flex: 3;}
.flex-item-4 {-webkit-flex: 4;flex: 4;}
.flex-item-5 {-webkit-flex: 5;flex: 5;}
.flex-row {-webkit-flex-flow: row;flex-flow: row;-webkit-flex-direction: row;flex-direction: row;}
.flex-column {-webkit-flex-flow: column;flex-flow: column;-webkit-flex-direction: column;flex-direction: column;}
.flex-left {-webkit-justify-content: flex-start;justify-content: flex-start;}
.flex-right {-webkit-justify-content: flex-end;justify-content: flex-end;}
.flex-center {-webkit-justify-content: center;justify-content: center;}
.flex-between {-webkit-justify-content: space-between;justify-content: space-between;}
.flex-around {-webkit-justify-content: space-around;justify-content: space-around;}
.flex-evenly{-webkit-justify-content: space-evenly;justify-content: space-evenly;}
.flex-top {-webkit-align-items: flex-start;align-items: flex-start;}
.flex-middle {-webkit-align-items: center;align-items: center;}
.flex-middle a {display: inline-block}
.flex-bottom {-webkit-align-items: flex-end;align-items: flex-end;}
.flex-baseline {-webkit-align-items: baseline;align-items: baseline;}
.flex-stretch {-webkit-align-items: stretch;align-items: stretch;}
.flex-wrap{flex-wrap: wrap;}

.flex_align{
  display: flex;
  align-items: center;
}
.space-between{
  justify-content: space-between;
}
.pointer {
  cursor: pointer;
}
.fl{
  float: left;
}
.fr{
  float: right;
}
.clearfix {
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
}
.w{
  width: 1200px;
  margin: auto;
}
.w210{
  width: 210px;
}
.ellipsis{
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
::-webkit-scrollbar {
  width: 5px;
  height:5px;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(0,0,0,.2);
}
::-webkit-scrollbar-track {
  border-radius: 0;
  background: #fff;
}
.text-warp{
  color: var(--el-color-black);
  border-color: transparent;
  background-color: transparent;
  word-wrap:break-word;
  max-width: 100%;
  cursor: pointer;
  &:hover{
    color: var(--el-color-primary);
  }
  &:active{
    color: var(--el-color-primary);
  }
}
[hidden],template{
  display: none;
}
.tx-ov{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.tx-ov-2 {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.iconfont{
  cursor: pointer;
}
.arrows {
  position: relative;
  padding-right: 20px;
}

.arrows:after {
  content: "";
  position: absolute;
  right: 8px;
  top: 50%;
  margin-top: -0.3em;
  width: 0.5em;
  height: 0.5em;
  border-top: solid 1px;
  border-right: solid 1px;
  transform: rotate(45deg);
}

.arrows-top {
  position: relative;
  padding-right: 20px;
}

.arrows-top:after {
  content: "";
  position: absolute;
  right: 8px;
  top: 50%;
  margin-top: -0.1em;
  width: 0.5em;
  height: 0.5em;
  border-top: solid 1px;
  border-right: solid 1px;
  transform: rotate(-45deg);
}

.arrows-left {
  position: relative;
  padding-right: 20px;
}

.arrows-left:after {
  content: "";
  position: absolute;
  right: 6px;
  top: 50%;
  margin-top: -0.2em;
  width: 0.5em;
  height: 0.5em;
  border-top: solid 1px;
  border-right: solid 1px;
  transform: rotate(-135deg);
}

.arrows-bottom {
  position: relative;
  padding-right: 20px;
}

.arrows-bottom:after {
  content: "";
  position: absolute;
  right: 5px;
  top: 50%;
  margin-top: -0.4em;
  width: 0.5em;
  height: 0.5em;
  border-top: solid 1px;
  border-right: solid 1px;
  transform: rotate(135deg);
}

.triangle-top{
  width: 0;
  height: 0;
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
}
.triangle-bottom {
  width: 0;
  height: 0;
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  border-top: 0.3em solid;
}
.triangle-left {
  width: 0;
  height: 0;
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.triangle-right {
  width: 0;
  height: 0;
  border-top: 0.3em solid transparent;
  border-left: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.pointer{
  cursor: pointer;
}
.relative{
  position: relative;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.mw112{
  max-width: 112px;
}
.share_box_content{
  background-color: transparent;
  height: 100vh !important;
  display: flex;
  align-items: center;
  justify-content: center;
  .el-drawer__header{
    width: 100%;
  }
  .el-drawer__close-btn{
    color:#FFFFFF;
    padding: 11px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 30px;
    display: flex;
    &:hover{
      background-color:rgba(255, 255, 255, 0.5);
    }
  }
}
.icon-collections{
  color: #ff9d00;
}

.app_container {
  padding-bottom: 25px;
}
.pdt51{
  padding-top: 51px;
}
.note_info_content{
  white-space: pre-wrap;
  word-break: break-all;
}
.both{
  font-weight: bold;
  color: #BFBFBF;
}
mark{
  color: var(--el-color-primary);
  background-color: #fff;
}
.detail_desc_content{
  line-height: 32px;
}
.tip_bg_visible{
  background: #FFFFFF;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.18);
  border-radius: 2px;
  padding: 18px 16px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  .el-icon{
    color: #F23C3C;
    margin-right: 12px;
  }
}

.footer_border_dialog{
  .el-dialog__header{
    border-bottom: 1px solid #EBEEF5;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 0;
  }
  .el-dialog__footer{
    padding-top: 14px;
    padding-bottom: 14px;
    border-top: 1px solid #EBEEF5;
  }
}
.pagination.is-background .el-pager li:not(.is-disabled).is-active{
  background: #2256F6;
  border-radius: 3px;
  color: #FFFFFF;
}
.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
  background: #FFFFFF;
  border-radius: 3px;
  border: 1px solid #EBEEF5;
  color: #999999;
}
.bplayer-control-wrap .bplayer-control-full span{
  opacity: 0 !important;
  width: calc(100%) !important;
  height: 100% !important;
}

.watermark {
  height: 100%;
  width: 100%;
  z-index: 99;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.bplayer-control-bar{
  padding: 0 !important;
  .left .bplayer-play-btn{
    margin-left: 0 !important;
  }
  .right .bplayer-logo-link{
    display: none !important;
  }
}
.pv-video-player{
 height: 100% !important;
}

.pv-cover .pv-icon-btn-play{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  height: 100% !important;
  opacity: 0;
}
.bplayer-action-tip{
  display: none !important;
}
.bplayer-title{
  display: none !important;
}



</style>
