import { default as certificateChD9TlQ28UMeta } from "/data/source/edu/education-web/pages/account/certificate.vue?macro=true";
import { default as collect9veo7cIFWJMeta } from "/data/source/edu/education-web/pages/account/collect.vue?macro=true";
import { default as couponoSjPsvbcq1Meta } from "/data/source/edu/education-web/pages/account/coupon.vue?macro=true";
import { default as courseieCEf8G8g7Meta } from "/data/source/edu/education-web/pages/account/course.vue?macro=true";
import { default as gradekA68xaf3DLMeta } from "/data/source/edu/education-web/pages/account/grade.vue?macro=true";
import { default as infoS3wRYz3EcOMeta } from "/data/source/edu/education-web/pages/account/info.vue?macro=true";
import { default as index9fwwZDvrIEMeta } from "/data/source/edu/education-web/pages/account/message/index.vue?macro=true";
import { default as noteJdmULyxbkmMeta } from "/data/source/edu/education-web/pages/account/note.vue?macro=true";
import { default as indexcwHV9zwWvpMeta } from "/data/source/edu/education-web/pages/account/order/index.vue?macro=true";
import { default as paper5CvbPP6NZoMeta } from "/data/source/edu/education-web/pages/account/paper.vue?macro=true";
import { default as listxRIZV4lIF0Meta } from "/data/source/edu/education-web/pages/article/list.vue?macro=true";
import { default as viewMizeR9Lhc3Meta } from "/data/source/edu/education-web/pages/article/view.vue?macro=true";
import { default as audio9rDfGCWCcvMeta } from "/data/source/edu/education-web/pages/course/audio.vue?macro=true";
import { default as combineAG3q8LprqsMeta } from "/data/source/edu/education-web/pages/course/combine.vue?macro=true";
import { default as graphicriuNi9DmLrMeta } from "/data/source/edu/education-web/pages/course/graphic.vue?macro=true";
import { default as listEmh8LvtK7TMeta } from "/data/source/edu/education-web/pages/course/list.vue?macro=true";
import { default as liveuGh3o3lIKvMeta } from "/data/source/edu/education-web/pages/course/live.vue?macro=true";
import { default as singleJElnsmqy59Meta } from "/data/source/edu/education-web/pages/course/single.vue?macro=true";
import { default as subjectkrdoNrFCoRMeta } from "/data/source/edu/education-web/pages/course/subject.vue?macro=true";
import { default as topic5zfwzOizuWMeta } from "/data/source/edu/education-web/pages/course/topic.vue?macro=true";
import { default as video9fgBfqmWGdMeta } from "/data/source/edu/education-web/pages/course/video.vue?macro=true";
import { default as analysisPZkNQkj8DEMeta } from "/data/source/edu/education-web/pages/exam/analysis.vue?macro=true";
import { default as emigratedGmFIPCSaHIMeta } from "/data/source/edu/education-web/pages/exam/emigrated.vue?macro=true";
import { default as indexEN9F9M0fUJMeta } from "/data/source/edu/education-web/pages/exam/index.vue?macro=true";
import { default as listcMeetap0h3Meta } from "/data/source/edu/education-web/pages/exam/list.vue?macro=true";
import { default as resultmpT2exvP3cMeta } from "/data/source/edu/education-web/pages/exam/result.vue?macro=true";
import { default as startExamBxwvLXXqAJMeta } from "/data/source/edu/education-web/pages/exam/startExam.vue?macro=true";
import { default as homeworkfzKFUrccS9Meta } from "/data/source/edu/education-web/pages/grade/homework.vue?macro=true";
import { default as listPeKlErcEGFMeta } from "/data/source/edu/education-web/pages/grade/list.vue?macro=true";
import { default as practiceqgVZK2ScHiMeta } from "/data/source/edu/education-web/pages/grade/practice.vue?macro=true";
import { default as viewcGxJQXxBHzMeta } from "/data/source/edu/education-web/pages/grade/view.vue?macro=true";
import { default as indexHYkfCbDFg2Meta } from "/data/source/edu/education-web/pages/index.vue?macro=true";
import { default as agreement1THizFCiWfMeta } from "/data/source/edu/education-web/pages/login/agreement.vue?macro=true";
import { default as indexWnmt0HRZnlMeta } from "/data/source/edu/education-web/pages/login/index.vue?macro=true";
import { default as emigratedVBcqnWFthrMeta } from "/data/source/edu/education-web/pages/paper/emigrated.vue?macro=true";
import { default as index96ofgwnLs5Meta } from "/data/source/edu/education-web/pages/paper/index.vue?macro=true";
import { default as listSMaHPSIeVHMeta } from "/data/source/edu/education-web/pages/paper/list.vue?macro=true";
import { default as viewlcpBIsBuMzMeta } from "/data/source/edu/education-web/pages/paper/view.vue?macro=true";
import { default as buyjYzCga61jZMeta } from "/data/source/edu/education-web/pages/pay/buy.vue?macro=true";
import { default as paySuccessDA1JOqs6XGMeta } from "/data/source/edu/education-web/pages/pay/paySuccess.vue?macro=true";
import { default as analysisyuWrGdIarYMeta } from "/data/source/edu/education-web/pages/practice/analysis.vue?macro=true";
import { default as dailyl5IqMSFdx9Meta } from "/data/source/edu/education-web/pages/practice/daily.vue?macro=true";
import { default as dailyProblemsbNO3VrAdSMeta } from "/data/source/edu/education-web/pages/practice/dailyProblem.vue?macro=true";
import { default as errorQlAtOZnmDFMeta } from "/data/source/edu/education-web/pages/practice/error.vue?macro=true";
import { default as errorAnalysisf3SjMS9JwuMeta } from "/data/source/edu/education-web/pages/practice/errorAnalysis.vue?macro=true";
import { default as errorProblem6HeWrfnferMeta } from "/data/source/edu/education-web/pages/practice/errorProblem.vue?macro=true";
import { default as errorResultoOnm64pCfYMeta } from "/data/source/edu/education-web/pages/practice/errorResult.vue?macro=true";
import { default as resultVJr4TXl7YqMeta } from "/data/source/edu/education-web/pages/practice/result.vue?macro=true";
import { default as startPracticeCHdNrNSgIdMeta } from "/data/source/edu/education-web/pages/practice/startPractice.vue?macro=true";
import { default as viewtvMMz18h9LMeta } from "/data/source/edu/education-web/pages/practice/view.vue?macro=true";
import { default as indexFNcN6xLLnpMeta } from "/data/source/edu/education-web/pages/register/index.vue?macro=true";
import { default as index68IVAM3wRuMeta } from "/data/source/edu/education-web/pages/resetpwd/index.vue?macro=true";
import { default as indexOtBo2hbtK6Meta } from "/data/source/edu/education-web/pages/search/index.vue?macro=true";
import { default as listlqclWYAj43Meta } from "/data/source/edu/education-web/pages/teacher/list.vue?macro=true";
import { default as viewX2BzqYAf1WMeta } from "/data/source/edu/education-web/pages/teacher/view.vue?macro=true";
import { default as indexmWZVvREFlpMeta } from "/data/source/edu/education-web/pages/vip/index.vue?macro=true";
import { default as successGefBG9r7hnMeta } from "/data/source/edu/education-web/pages/wx/success.vue?macro=true";
import { default as wxQvgoEAkztxMeta } from "/data/source/edu/education-web/pages/wx/wx.vue?macro=true";
export default [
  {
    name: certificateChD9TlQ28UMeta?.name ?? "account-certificate",
    path: certificateChD9TlQ28UMeta?.path ?? "/account/certificate",
    meta: certificateChD9TlQ28UMeta || {},
    alias: certificateChD9TlQ28UMeta?.alias || [],
    redirect: certificateChD9TlQ28UMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/account/certificate.vue").then(m => m.default || m)
  },
  {
    name: collect9veo7cIFWJMeta?.name ?? "account-collect",
    path: collect9veo7cIFWJMeta?.path ?? "/account/collect",
    meta: collect9veo7cIFWJMeta || {},
    alias: collect9veo7cIFWJMeta?.alias || [],
    redirect: collect9veo7cIFWJMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/account/collect.vue").then(m => m.default || m)
  },
  {
    name: couponoSjPsvbcq1Meta?.name ?? "account-coupon",
    path: couponoSjPsvbcq1Meta?.path ?? "/account/coupon",
    meta: couponoSjPsvbcq1Meta || {},
    alias: couponoSjPsvbcq1Meta?.alias || [],
    redirect: couponoSjPsvbcq1Meta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/account/coupon.vue").then(m => m.default || m)
  },
  {
    name: courseieCEf8G8g7Meta?.name ?? "account-course",
    path: courseieCEf8G8g7Meta?.path ?? "/account/course",
    meta: courseieCEf8G8g7Meta || {},
    alias: courseieCEf8G8g7Meta?.alias || [],
    redirect: courseieCEf8G8g7Meta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/account/course.vue").then(m => m.default || m)
  },
  {
    name: gradekA68xaf3DLMeta?.name ?? "account-grade",
    path: gradekA68xaf3DLMeta?.path ?? "/account/grade",
    meta: gradekA68xaf3DLMeta || {},
    alias: gradekA68xaf3DLMeta?.alias || [],
    redirect: gradekA68xaf3DLMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/account/grade.vue").then(m => m.default || m)
  },
  {
    name: infoS3wRYz3EcOMeta?.name ?? "account-info",
    path: infoS3wRYz3EcOMeta?.path ?? "/account/info",
    meta: infoS3wRYz3EcOMeta || {},
    alias: infoS3wRYz3EcOMeta?.alias || [],
    redirect: infoS3wRYz3EcOMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/account/info.vue").then(m => m.default || m)
  },
  {
    name: index9fwwZDvrIEMeta?.name ?? "account-message",
    path: index9fwwZDvrIEMeta?.path ?? "/account/message",
    meta: index9fwwZDvrIEMeta || {},
    alias: index9fwwZDvrIEMeta?.alias || [],
    redirect: index9fwwZDvrIEMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/account/message/index.vue").then(m => m.default || m)
  },
  {
    name: noteJdmULyxbkmMeta?.name ?? "account-note",
    path: noteJdmULyxbkmMeta?.path ?? "/account/note",
    meta: noteJdmULyxbkmMeta || {},
    alias: noteJdmULyxbkmMeta?.alias || [],
    redirect: noteJdmULyxbkmMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/account/note.vue").then(m => m.default || m)
  },
  {
    name: indexcwHV9zwWvpMeta?.name ?? "account-order",
    path: indexcwHV9zwWvpMeta?.path ?? "/account/order",
    meta: indexcwHV9zwWvpMeta || {},
    alias: indexcwHV9zwWvpMeta?.alias || [],
    redirect: indexcwHV9zwWvpMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/account/order/index.vue").then(m => m.default || m)
  },
  {
    name: paper5CvbPP6NZoMeta?.name ?? "account-paper",
    path: paper5CvbPP6NZoMeta?.path ?? "/account/paper",
    meta: paper5CvbPP6NZoMeta || {},
    alias: paper5CvbPP6NZoMeta?.alias || [],
    redirect: paper5CvbPP6NZoMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/account/paper.vue").then(m => m.default || m)
  },
  {
    name: listxRIZV4lIF0Meta?.name ?? "article-list",
    path: listxRIZV4lIF0Meta?.path ?? "/article/list",
    meta: listxRIZV4lIF0Meta || {},
    alias: listxRIZV4lIF0Meta?.alias || [],
    redirect: listxRIZV4lIF0Meta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/article/list.vue").then(m => m.default || m)
  },
  {
    name: viewMizeR9Lhc3Meta?.name ?? "article-view",
    path: viewMizeR9Lhc3Meta?.path ?? "/article/view",
    meta: viewMizeR9Lhc3Meta || {},
    alias: viewMizeR9Lhc3Meta?.alias || [],
    redirect: viewMizeR9Lhc3Meta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/article/view.vue").then(m => m.default || m)
  },
  {
    name: audio9rDfGCWCcvMeta?.name ?? "course-audio",
    path: audio9rDfGCWCcvMeta?.path ?? "/course/audio",
    meta: audio9rDfGCWCcvMeta || {},
    alias: audio9rDfGCWCcvMeta?.alias || [],
    redirect: audio9rDfGCWCcvMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/course/audio.vue").then(m => m.default || m)
  },
  {
    name: combineAG3q8LprqsMeta?.name ?? "course-combine",
    path: combineAG3q8LprqsMeta?.path ?? "/course/combine",
    meta: combineAG3q8LprqsMeta || {},
    alias: combineAG3q8LprqsMeta?.alias || [],
    redirect: combineAG3q8LprqsMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/course/combine.vue").then(m => m.default || m)
  },
  {
    name: graphicriuNi9DmLrMeta?.name ?? "course-graphic",
    path: graphicriuNi9DmLrMeta?.path ?? "/course/graphic",
    meta: graphicriuNi9DmLrMeta || {},
    alias: graphicriuNi9DmLrMeta?.alias || [],
    redirect: graphicriuNi9DmLrMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/course/graphic.vue").then(m => m.default || m)
  },
  {
    name: listEmh8LvtK7TMeta?.name ?? "course-list",
    path: listEmh8LvtK7TMeta?.path ?? "/course/list",
    meta: listEmh8LvtK7TMeta || {},
    alias: listEmh8LvtK7TMeta?.alias || [],
    redirect: listEmh8LvtK7TMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/course/list.vue").then(m => m.default || m)
  },
  {
    name: liveuGh3o3lIKvMeta?.name ?? "course-live",
    path: liveuGh3o3lIKvMeta?.path ?? "/course/live",
    meta: liveuGh3o3lIKvMeta || {},
    alias: liveuGh3o3lIKvMeta?.alias || [],
    redirect: liveuGh3o3lIKvMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/course/live.vue").then(m => m.default || m)
  },
  {
    name: singleJElnsmqy59Meta?.name ?? "course-single",
    path: singleJElnsmqy59Meta?.path ?? "/course/single",
    meta: singleJElnsmqy59Meta || {},
    alias: singleJElnsmqy59Meta?.alias || [],
    redirect: singleJElnsmqy59Meta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/course/single.vue").then(m => m.default || m)
  },
  {
    name: subjectkrdoNrFCoRMeta?.name ?? "course-subject",
    path: subjectkrdoNrFCoRMeta?.path ?? "/course/subject",
    meta: subjectkrdoNrFCoRMeta || {},
    alias: subjectkrdoNrFCoRMeta?.alias || [],
    redirect: subjectkrdoNrFCoRMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/course/subject.vue").then(m => m.default || m)
  },
  {
    name: topic5zfwzOizuWMeta?.name ?? "course-topic",
    path: topic5zfwzOizuWMeta?.path ?? "/course/topic",
    meta: topic5zfwzOizuWMeta || {},
    alias: topic5zfwzOizuWMeta?.alias || [],
    redirect: topic5zfwzOizuWMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/course/topic.vue").then(m => m.default || m)
  },
  {
    name: video9fgBfqmWGdMeta?.name ?? "course-video",
    path: video9fgBfqmWGdMeta?.path ?? "/course/video",
    meta: video9fgBfqmWGdMeta || {},
    alias: video9fgBfqmWGdMeta?.alias || [],
    redirect: video9fgBfqmWGdMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/course/video.vue").then(m => m.default || m)
  },
  {
    name: analysisPZkNQkj8DEMeta?.name ?? "exam-analysis",
    path: analysisPZkNQkj8DEMeta?.path ?? "/exam/analysis",
    meta: analysisPZkNQkj8DEMeta || {},
    alias: analysisPZkNQkj8DEMeta?.alias || [],
    redirect: analysisPZkNQkj8DEMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/exam/analysis.vue").then(m => m.default || m)
  },
  {
    name: emigratedGmFIPCSaHIMeta?.name ?? "exam-emigrated",
    path: emigratedGmFIPCSaHIMeta?.path ?? "/exam/emigrated",
    meta: emigratedGmFIPCSaHIMeta || {},
    alias: emigratedGmFIPCSaHIMeta?.alias || [],
    redirect: emigratedGmFIPCSaHIMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/exam/emigrated.vue").then(m => m.default || m)
  },
  {
    name: indexEN9F9M0fUJMeta?.name ?? "exam",
    path: indexEN9F9M0fUJMeta?.path ?? "/exam",
    meta: indexEN9F9M0fUJMeta || {},
    alias: indexEN9F9M0fUJMeta?.alias || [],
    redirect: indexEN9F9M0fUJMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/exam/index.vue").then(m => m.default || m)
  },
  {
    name: listcMeetap0h3Meta?.name ?? "exam-list",
    path: listcMeetap0h3Meta?.path ?? "/exam/list",
    meta: listcMeetap0h3Meta || {},
    alias: listcMeetap0h3Meta?.alias || [],
    redirect: listcMeetap0h3Meta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/exam/list.vue").then(m => m.default || m)
  },
  {
    name: resultmpT2exvP3cMeta?.name ?? "exam-result",
    path: resultmpT2exvP3cMeta?.path ?? "/exam/result",
    meta: resultmpT2exvP3cMeta || {},
    alias: resultmpT2exvP3cMeta?.alias || [],
    redirect: resultmpT2exvP3cMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/exam/result.vue").then(m => m.default || m)
  },
  {
    name: startExamBxwvLXXqAJMeta?.name ?? "exam-startExam",
    path: startExamBxwvLXXqAJMeta?.path ?? "/exam/startExam",
    meta: startExamBxwvLXXqAJMeta || {},
    alias: startExamBxwvLXXqAJMeta?.alias || [],
    redirect: startExamBxwvLXXqAJMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/exam/startExam.vue").then(m => m.default || m)
  },
  {
    name: homeworkfzKFUrccS9Meta?.name ?? "grade-homework",
    path: homeworkfzKFUrccS9Meta?.path ?? "/grade/homework",
    meta: homeworkfzKFUrccS9Meta || {},
    alias: homeworkfzKFUrccS9Meta?.alias || [],
    redirect: homeworkfzKFUrccS9Meta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/grade/homework.vue").then(m => m.default || m)
  },
  {
    name: listPeKlErcEGFMeta?.name ?? "grade-list",
    path: listPeKlErcEGFMeta?.path ?? "/grade/list",
    meta: listPeKlErcEGFMeta || {},
    alias: listPeKlErcEGFMeta?.alias || [],
    redirect: listPeKlErcEGFMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/grade/list.vue").then(m => m.default || m)
  },
  {
    name: practiceqgVZK2ScHiMeta?.name ?? "grade-practice",
    path: practiceqgVZK2ScHiMeta?.path ?? "/grade/practice",
    meta: practiceqgVZK2ScHiMeta || {},
    alias: practiceqgVZK2ScHiMeta?.alias || [],
    redirect: practiceqgVZK2ScHiMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/grade/practice.vue").then(m => m.default || m)
  },
  {
    name: viewcGxJQXxBHzMeta?.name ?? "grade-view",
    path: viewcGxJQXxBHzMeta?.path ?? "/grade/view",
    meta: viewcGxJQXxBHzMeta || {},
    alias: viewcGxJQXxBHzMeta?.alias || [],
    redirect: viewcGxJQXxBHzMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/grade/view.vue").then(m => m.default || m)
  },
  {
    name: indexHYkfCbDFg2Meta?.name ?? "index",
    path: indexHYkfCbDFg2Meta?.path ?? "/",
    meta: indexHYkfCbDFg2Meta || {},
    alias: indexHYkfCbDFg2Meta?.alias || [],
    redirect: indexHYkfCbDFg2Meta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: agreement1THizFCiWfMeta?.name ?? "login-agreement",
    path: agreement1THizFCiWfMeta?.path ?? "/login/agreement",
    meta: agreement1THizFCiWfMeta || {},
    alias: agreement1THizFCiWfMeta?.alias || [],
    redirect: agreement1THizFCiWfMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/login/agreement.vue").then(m => m.default || m)
  },
  {
    name: indexWnmt0HRZnlMeta?.name ?? "login",
    path: indexWnmt0HRZnlMeta?.path ?? "/login",
    meta: indexWnmt0HRZnlMeta || {},
    alias: indexWnmt0HRZnlMeta?.alias || [],
    redirect: indexWnmt0HRZnlMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: emigratedVBcqnWFthrMeta?.name ?? "paper-emigrated",
    path: emigratedVBcqnWFthrMeta?.path ?? "/paper/emigrated",
    meta: emigratedVBcqnWFthrMeta || {},
    alias: emigratedVBcqnWFthrMeta?.alias || [],
    redirect: emigratedVBcqnWFthrMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/paper/emigrated.vue").then(m => m.default || m)
  },
  {
    name: index96ofgwnLs5Meta?.name ?? "paper",
    path: index96ofgwnLs5Meta?.path ?? "/paper",
    meta: index96ofgwnLs5Meta || {},
    alias: index96ofgwnLs5Meta?.alias || [],
    redirect: index96ofgwnLs5Meta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/paper/index.vue").then(m => m.default || m)
  },
  {
    name: listSMaHPSIeVHMeta?.name ?? "paper-list",
    path: listSMaHPSIeVHMeta?.path ?? "/paper/list",
    meta: listSMaHPSIeVHMeta || {},
    alias: listSMaHPSIeVHMeta?.alias || [],
    redirect: listSMaHPSIeVHMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/paper/list.vue").then(m => m.default || m)
  },
  {
    name: viewlcpBIsBuMzMeta?.name ?? "paper-view",
    path: viewlcpBIsBuMzMeta?.path ?? "/paper/view",
    meta: viewlcpBIsBuMzMeta || {},
    alias: viewlcpBIsBuMzMeta?.alias || [],
    redirect: viewlcpBIsBuMzMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/paper/view.vue").then(m => m.default || m)
  },
  {
    name: buyjYzCga61jZMeta?.name ?? "pay-buy",
    path: buyjYzCga61jZMeta?.path ?? "/pay/buy",
    meta: buyjYzCga61jZMeta || {},
    alias: buyjYzCga61jZMeta?.alias || [],
    redirect: buyjYzCga61jZMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/pay/buy.vue").then(m => m.default || m)
  },
  {
    name: paySuccessDA1JOqs6XGMeta?.name ?? "pay-paySuccess",
    path: paySuccessDA1JOqs6XGMeta?.path ?? "/pay/paySuccess",
    meta: paySuccessDA1JOqs6XGMeta || {},
    alias: paySuccessDA1JOqs6XGMeta?.alias || [],
    redirect: paySuccessDA1JOqs6XGMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/pay/paySuccess.vue").then(m => m.default || m)
  },
  {
    name: analysisyuWrGdIarYMeta?.name ?? "practice-analysis",
    path: analysisyuWrGdIarYMeta?.path ?? "/practice/analysis",
    meta: analysisyuWrGdIarYMeta || {},
    alias: analysisyuWrGdIarYMeta?.alias || [],
    redirect: analysisyuWrGdIarYMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/practice/analysis.vue").then(m => m.default || m)
  },
  {
    name: dailyl5IqMSFdx9Meta?.name ?? "practice-daily",
    path: dailyl5IqMSFdx9Meta?.path ?? "/practice/daily",
    meta: dailyl5IqMSFdx9Meta || {},
    alias: dailyl5IqMSFdx9Meta?.alias || [],
    redirect: dailyl5IqMSFdx9Meta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/practice/daily.vue").then(m => m.default || m)
  },
  {
    name: dailyProblemsbNO3VrAdSMeta?.name ?? "practice-dailyProblem",
    path: dailyProblemsbNO3VrAdSMeta?.path ?? "/practice/dailyProblem",
    meta: dailyProblemsbNO3VrAdSMeta || {},
    alias: dailyProblemsbNO3VrAdSMeta?.alias || [],
    redirect: dailyProblemsbNO3VrAdSMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/practice/dailyProblem.vue").then(m => m.default || m)
  },
  {
    name: errorQlAtOZnmDFMeta?.name ?? "practice-error",
    path: errorQlAtOZnmDFMeta?.path ?? "/practice/error",
    meta: errorQlAtOZnmDFMeta || {},
    alias: errorQlAtOZnmDFMeta?.alias || [],
    redirect: errorQlAtOZnmDFMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/practice/error.vue").then(m => m.default || m)
  },
  {
    name: errorAnalysisf3SjMS9JwuMeta?.name ?? "practice-errorAnalysis",
    path: errorAnalysisf3SjMS9JwuMeta?.path ?? "/practice/errorAnalysis",
    meta: errorAnalysisf3SjMS9JwuMeta || {},
    alias: errorAnalysisf3SjMS9JwuMeta?.alias || [],
    redirect: errorAnalysisf3SjMS9JwuMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/practice/errorAnalysis.vue").then(m => m.default || m)
  },
  {
    name: errorProblem6HeWrfnferMeta?.name ?? "practice-errorProblem",
    path: errorProblem6HeWrfnferMeta?.path ?? "/practice/errorProblem",
    meta: errorProblem6HeWrfnferMeta || {},
    alias: errorProblem6HeWrfnferMeta?.alias || [],
    redirect: errorProblem6HeWrfnferMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/practice/errorProblem.vue").then(m => m.default || m)
  },
  {
    name: errorResultoOnm64pCfYMeta?.name ?? "practice-errorResult",
    path: errorResultoOnm64pCfYMeta?.path ?? "/practice/errorResult",
    meta: errorResultoOnm64pCfYMeta || {},
    alias: errorResultoOnm64pCfYMeta?.alias || [],
    redirect: errorResultoOnm64pCfYMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/practice/errorResult.vue").then(m => m.default || m)
  },
  {
    name: resultVJr4TXl7YqMeta?.name ?? "practice-result",
    path: resultVJr4TXl7YqMeta?.path ?? "/practice/result",
    meta: resultVJr4TXl7YqMeta || {},
    alias: resultVJr4TXl7YqMeta?.alias || [],
    redirect: resultVJr4TXl7YqMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/practice/result.vue").then(m => m.default || m)
  },
  {
    name: startPracticeCHdNrNSgIdMeta?.name ?? "practice-startPractice",
    path: startPracticeCHdNrNSgIdMeta?.path ?? "/practice/startPractice",
    meta: startPracticeCHdNrNSgIdMeta || {},
    alias: startPracticeCHdNrNSgIdMeta?.alias || [],
    redirect: startPracticeCHdNrNSgIdMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/practice/startPractice.vue").then(m => m.default || m)
  },
  {
    name: viewtvMMz18h9LMeta?.name ?? "practice-view",
    path: viewtvMMz18h9LMeta?.path ?? "/practice/view",
    meta: viewtvMMz18h9LMeta || {},
    alias: viewtvMMz18h9LMeta?.alias || [],
    redirect: viewtvMMz18h9LMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/practice/view.vue").then(m => m.default || m)
  },
  {
    name: indexFNcN6xLLnpMeta?.name ?? "register",
    path: indexFNcN6xLLnpMeta?.path ?? "/register",
    meta: indexFNcN6xLLnpMeta || {},
    alias: indexFNcN6xLLnpMeta?.alias || [],
    redirect: indexFNcN6xLLnpMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: index68IVAM3wRuMeta?.name ?? "resetpwd",
    path: index68IVAM3wRuMeta?.path ?? "/resetpwd",
    meta: index68IVAM3wRuMeta || {},
    alias: index68IVAM3wRuMeta?.alias || [],
    redirect: index68IVAM3wRuMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/resetpwd/index.vue").then(m => m.default || m)
  },
  {
    name: indexOtBo2hbtK6Meta?.name ?? "search",
    path: indexOtBo2hbtK6Meta?.path ?? "/search",
    meta: indexOtBo2hbtK6Meta || {},
    alias: indexOtBo2hbtK6Meta?.alias || [],
    redirect: indexOtBo2hbtK6Meta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: listlqclWYAj43Meta?.name ?? "teacher-list",
    path: listlqclWYAj43Meta?.path ?? "/teacher/list",
    meta: listlqclWYAj43Meta || {},
    alias: listlqclWYAj43Meta?.alias || [],
    redirect: listlqclWYAj43Meta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/teacher/list.vue").then(m => m.default || m)
  },
  {
    name: viewX2BzqYAf1WMeta?.name ?? "teacher-view",
    path: viewX2BzqYAf1WMeta?.path ?? "/teacher/view",
    meta: viewX2BzqYAf1WMeta || {},
    alias: viewX2BzqYAf1WMeta?.alias || [],
    redirect: viewX2BzqYAf1WMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/teacher/view.vue").then(m => m.default || m)
  },
  {
    name: indexmWZVvREFlpMeta?.name ?? "vip",
    path: indexmWZVvREFlpMeta?.path ?? "/vip",
    meta: indexmWZVvREFlpMeta || {},
    alias: indexmWZVvREFlpMeta?.alias || [],
    redirect: indexmWZVvREFlpMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/vip/index.vue").then(m => m.default || m)
  },
  {
    name: successGefBG9r7hnMeta?.name ?? "wx-success",
    path: successGefBG9r7hnMeta?.path ?? "/wx/success",
    meta: successGefBG9r7hnMeta || {},
    alias: successGefBG9r7hnMeta?.alias || [],
    redirect: successGefBG9r7hnMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/wx/success.vue").then(m => m.default || m)
  },
  {
    name: wxQvgoEAkztxMeta?.name ?? "wx-wx",
    path: wxQvgoEAkztxMeta?.path ?? "/wx/wx",
    meta: wxQvgoEAkztxMeta || {},
    alias: wxQvgoEAkztxMeta?.alias || [],
    redirect: wxQvgoEAkztxMeta?.redirect || undefined,
    component: () => import("/data/source/edu/education-web/pages/wx/wx.vue").then(m => m.default || m)
  }
]